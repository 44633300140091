import { CssBaseline } from "@mui/material";
import React, { useState, useEffect } from "react";
import OrderApprovalTable from "../../organisms/order_approval";
import { columns } from "../order_approval/constants";
import { callGraphQL } from "../../../api/graphql_api";

const getOrders = async (orderType) => {
  let token = "";
  let hasMore = true;
  let data = [];
  while (hasMore) {
    const resp = await callGraphQL(`get_${orderType}_orders`, {
      pagination: { limit: 300, token },
    });
    data.push(...resp.data.data.get_approve_requests.approve_requests);
    token = resp.data.data.get_approve_requests.pagination_token;
    hasMore = token !== null;
  }
  for (const request of data) {
    const { approve_id } = request;
    const resp = await callGraphQL("get_orders_by_apporve_id", {
      approve_id,
    });
    request.orders = resp.data.data.get_orders_by_approve_id.orders;
  }
  return data;
};

export const OrderApprovalPage = () => {
  // eslint-disable-next-line
  const [viewData, setViewData] = useState([]);
  const columnsMemo = React.useMemo(() => columns, []);
  const dataMemo = React.useMemo(() => viewData, [viewData]);

  const getViewData = React.useCallback(async () => {
    try {
      const pendingOrders = await getOrders("pending");
      const approvedOrders = await getOrders("approved");
      const deniedOrders = await getOrders("denied");
      const data = [...pendingOrders, ...approvedOrders, ...deniedOrders];
      setViewData(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const approveOrder = async (approve_id, approve_status) => {
    await callGraphQL("approve_orders", {
      approve_id,
      approve_status,
    });
    window.location.reload(false);
  };

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  return (
    <>
      <CssBaseline />
      <OrderApprovalTable
        columns={columnsMemo}
        data={dataMemo}
        approveOrder={approveOrder}
      />
    </>
  );
};

export default OrderApprovalPage;
